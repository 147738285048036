import { useRouter } from 'next/router';
import { Button, Form, Input, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import Logo from '../../components/logo';
import axios from 'axios';
// import { GoogleLoginButton } from 'react-social-login-buttons';
import { getProviders, getSession } from 'next-auth/client';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import styles from '../../styles/auth.module.css';

export const loginAction = (values) => {
  return {
    type: 'LOGIN_WITH_CREDENTIALS_ONLY',
    payload: new Promise(resolve => resolve(values))
  };
};

export const SignIn = () => {
  const [loginFailed, setLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (loginFailed) {
        setLoginFailed(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [loginFailed]);

  const onFinish = async (formValues) => {
    setLoading(true);
    const result = await axios.post(`${process.env.NEXT_PUBLIC_SERVER}/api/auth/login`, formValues);
    if (result.status === 200 && result.data.passwordValid && !result.data.error) {
      // dispatch login information to redux
      await dispatch(loginAction({
        ...formValues,
        ...result.data
      }));

      // handle different user state cases
      // email unverified takes priority
      // followed by an unconnected authenticator
      // followed by pending
      if (!result.data.authenticatorConnected) {
        return router.push({
          pathname: '/register/authenticator',
          query: {
            callbackUrl: router.query.callbackUrl
          }
        });
      } else if (!result.data.emailVerified) {
        return router.push({
          pathname: '/register/verify',
          query: {
            callbackUrl: router.query.callbackUrl
          }
        });
      } else if (result.data.userStatus === 'PENDING') {
        return router.push({
          pathname: '/register/pending',
          query: {
            callbackUrl: router.query.callbackUrl
          }
        });
      } else {
        // email is verified and authenticator is connected and user is not pending verification 
        return router.push({
          pathname: '/login/authenticator',
          query: {
            callbackUrl: router.query.callbackUrl 
          }
        });
      }
    } else {
      setLoginFailed(true);
    }
    setLoading(false);
  };

  return (
    <div
      style={{
        backgroundColor: '#FAFAFA',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        className={loginFailed ? classnames(styles.shake, styles.authBox) : styles.authBox}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '50px 0px'
          }}
        >
          <Logo />
        </div>

        {
          loading ?
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Spin size='large' />
            </div>
            :
            <Form
              name='login'
              style={{
                maxWidth: '500px'
              }}
              onFinish={onFinish}
            >
              <Form.Item
                name='email'
                rules={[{ required: true, message: 'Please enter your email!' }]}
              >
                <Input prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Email address' />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[{ required: true, message: 'Please enter your password!' }]}
              >
                <Input
                  prefix={<LockOutlined className='site-form-item-icon' />}
                  type='password'
                  placeholder='Password'
                />
              </Form.Item>
              <Form.Item>
                <a style={{ float: 'right', marginTop: '-30px' }} href=''>
                  Forgot password
                </a>
              </Form.Item>

              <Form.Item>
                <Button type='primary' htmlType='submit' style={{ backgroundColor: '#1700F1', width: '100%' }}>
                  Log in
                </Button>
              </Form.Item>
              <Form.Item>
                <Button type='text' onClick={() => router.push('/register')} style={{ border: '1px solid #636363', width: '100%' }}>
                  Sign up
                </Button>
              </Form.Item>
              {/* <Form.Item disabled>
                <GoogleLoginButton
                  disabled
                  style={{
                    fontSize: '14px',
                    lineHeight: '1.5715',
                    height: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    margin: 0
                  }}
                  onClick={() => signIn(providers['google'].id)}
                />
              </Form.Item> */}
            </Form>
        }
      </div>

    </div>
  );
};

export async function getServerSideProps(context) {
  const session = await getSession({ req: context.req });

  if (session) {
    context.res.setHeader('Location', '/');
    context.res.statusCode = 302;
    return { props: {} };
  }

  const providers = await getProviders();
  return {
    props: { providers }
  };
}

SignIn.propTypes = {
  providers: PropTypes.object
};

export default SignIn;
